import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.statusClass)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode("STATUS COMPONENT")
    ])
  ], 2)), [
    [_vShow, !_ctx.isSearchOpen]
  ])
}