const makeStyle = (style: string, textColor = "white") => {
  return {
    textColor,
    color: style,
  };
};
export const styles = {
  info: makeStyle("info", "black"),
  primary: makeStyle("primary", "white"),
  error: makeStyle("negative", "white"),
  success: makeStyle("positive", "white"),
  warning: makeStyle("warning", "black"),
};
export type NotifyStyles = keyof typeof styles;
