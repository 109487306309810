/**
 * Методы для работы со ссылками
 */

import { DOMAIN_DEVELOPMENT, DOMAIN_PRODUCTION, isDevEnv } from "./constants";

const /**
   * Возвращает истину, если переданная ссылка ведет
   * на старый дизайн Dashboard
   * @param {string}
   * @return {boolean}
   */
  isOldDashboardUrl = (url: string): boolean => {
    return (
      url.indexOf(isDevEnv() ? DOMAIN_DEVELOPMENT : DOMAIN_PRODUCTION) != -1
    );
  },
  /**
   * Возвращает истину, если url относительный
   * @param {string}
   * @returns {boolean}
   */
  isRelativeUrl = (url: string) => /^\//.test(url),
  /**
   * Возвращает истину, если url абсолютный
   * @param {string}
   * @returns {boolean}
   */
  isAbsoluteUrl = (url: string) => !isRelativeUrl(url),
  /**
   * Определяет необходимость в свойстве target
   * @param {string} url
   * @returns {string}
   */
  defineTarget = (url: string): string => {
    if (isOldDashboardUrl(url) || isRelativeUrl(url)) {
      // ссылки с dashboard и spa открываем в этой же вкладке
      return "_self";
    }
    return "_blank";
  };

export { isOldDashboardUrl, isRelativeUrl, isAbsoluteUrl, defineTarget };
