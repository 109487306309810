import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b784898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alarm" }
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "photo_wrapper"
}
const _hoisted_5 = { class: "photo-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "alarm__wrapper",
      style: _normalizeStyle({ backgroundColor: _ctx.item?.color })
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item?.caption), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item?.content), 1),
      (_ctx.item?.photo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "photo",
                src: _ctx.item.photo
              }, null, 8, _hoisted_6),
              _createElementVNode("img", {
                class: "alarm-icon",
                src: _ctx.alarmImage
              }, null, 8, _hoisted_7)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}