
import IndexAlarm from "@/models/index/index/IndexAlarm";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<IndexAlarm>,
      require: true,
    },
  },
  computed: {
    /**
     * Подключает нужную картинку
     * @return string
     */
    alarmImage(): string {
      return require("@/assets/img/icons/icon_alarm.svg");
    },
  },
});
