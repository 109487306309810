import { isBuild } from "@/composables/constants";

const DASHBOARD_KEY_PREFIX = "_dashboard",
  OLD_DASHBOARD_KEY_PREFIX = "dashboard";
let dashboardKey = DASHBOARD_KEY_PREFIX + "-0";

/**
 * Возвращает имя ключа, по которому
 * хранятся данные пользователя в localStorage
 * @returns {string}
 */
function getDashboardKey(useOldDesign = false): string {
  if (useOldDesign) {
    return dashboardKey.replace(DASHBOARD_KEY_PREFIX, OLD_DASHBOARD_KEY_PREFIX);
  }
  return dashboardKey;
}
/**
 * Устанавливает новый ключ, где должны храниться данные пользователя в localStorage
 * @param {null | number} userId
 */
export function setDashboardKey(userId: null | number) {
  dashboardKey = DASHBOARD_KEY_PREFIX + "-" + (userId === null ? 0 : userId);
}

/**
 * Возвращает данные из хранилища localStorage пользователя.
 * Если у пользователя еще не было данных, то инициализирует localStorage пользователя
 * @returns any
 */
function getDashboardData(useOldDesign = false) {
  const dashboardKey = getDashboardKey(useOldDesign),
    dashboardDataStr = localStorage.getItem(dashboardKey);
  let dashboardData;
  if (dashboardDataStr === null) {
    dashboardData = {};
    localStorage.setItem(dashboardKey, JSON.stringify(dashboardData));
  } else {
    dashboardData = JSON.parse(dashboardDataStr);
  }
  return dashboardData;
}

/**
 *
 * Возвращает значение из хранилища localStorage
 * Если ключ не существует, то вернется undefined
 * @param {string} key ключ
 * @returns {undefined | T}
 */
export function lsGet<T>(key: string): undefined | T {
  const dashboardData = getDashboardData();

  return typeof dashboardData[key] === "undefined"
    ? undefined
    : (dashboardData[key] as T);
}
/**
 * Записывает значение в хранилище localStorage
 * @param {string} key
 * @param {T} value
 */
export function lsSet<T>(key: string, value: T): void {
  const dashboardData = getDashboardData();

  dashboardData[key] = value;
  localStorage.setItem(getDashboardKey(), JSON.stringify(dashboardData));
}
/**
 *
 * Возвращает значение из хранилища localStorage старого дизайна
 * Если ключ не существует, то вернется undefined
 * @param {string} key ключ
 * @returns {undefined | T}
 */
export function lsGetFromOldDesign<T>(key: string): undefined | T {
  const dashboardData = getDashboardData(true);

  if (!isBuild()) {
    // Находимся на 192.168.1. ... в процессе разработки, нет доступа к dev
    return {} as T;
  }
  return typeof dashboardData[key] === "undefined"
    ? undefined
    : (dashboardData[key] as T);
}
/**
 * Записывает значение в хранилище localStorage старого дизайна
 * @param {string} key
 * @param {T} value
 */
export function lsSetToOldDesign<T>(key: string, value: T): void {
  const dashboardData = getDashboardData(true);

  dashboardData[key] = value;
  localStorage.setItem(getDashboardKey(true), JSON.stringify(dashboardData));
}
