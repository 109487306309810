import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_AppSearch = _resolveComponent("AppSearch")!
  const _component_AppProfile = _resolveComponent("AppProfile")!
  const _component_AppKitfortSite = _resolveComponent("AppKitfortSite")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.navigationClass),
    style: _normalizeStyle(_ctx.navigationPositionStyle),
    ref: "navigation"
  }, [
    _createVNode(_component_AppLogo, { class: "logo" }),
    (_ctx.isAuthorizedUser)
      ? (_openBlock(), _createBlock(_component_AppSearch, {
          key: 0,
          class: "search"
        }))
      : _createCommentVNode("", true),
    (_ctx.isAuthorizedUser)
      ? (_openBlock(), _createBlock(_component_AppProfile, {
          key: 1,
          class: "profile"
        }))
      : _createCommentVNode("", true),
    (!_ctx.isAuthorizedUser)
      ? (_openBlock(), _createBlock(_component_AppKitfortSite, { key: 2 }))
      : _createCommentVNode("", true)
  ], 6))
}