
import ListItem from "@/models/base/ListItem";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<ListItem>>,
      required: true,
    },
    // Первоначальный выбранный пункт
    modelValue: {
      type: [Object, null] as PropType<null | ListItem>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  beforeMount() {
    if (this.modelValue) {
      this.selectedItem = this.modelValue;
    }
  },
  data() {
    return {
      selectedItem: null as null | ListItem,
    };
  },
  methods: {
    selectItem(item: ListItem) {
      this.selectedItem = item;
      this.$emit("update:modelValue", item);
    },
    getSelectedItem(): ListItem | null {
      return this.selectedItem;
    },
  },
});
