
/**
 * Простая обертка для ссылок.
 * Относительные ссылки проходят через router-link,
 * абсолютные через стандартный тег <a>
 */
import { useRootStore } from "@/stores/Root";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: "_blank",
    },
  },
  computed: {
    isRelativePath(): boolean {
      return !!this.url.match(/^\//);
    },
  },
  methods: {
    /**
     * Выполняет открытие url
     * @param {MouseEvent} event
     */
    openUrl(event: MouseEvent) {
      if (useRootStore().modal != null) {
        // Открыто модальное окно - открываем через window.open
        event.preventDefault();
        window.open(this.url, this.target);
      }
    },
  },
});
