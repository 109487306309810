
import { defineComponent } from "vue";
import ListItem from "@/models/base/ListItem";
import BaseBtnDropdown from "@/components/base/BaseBtnDropdown.vue";
import BaseDropdownList from "@/components/base/BaseDropdownList.vue";
import { useRootStore } from "@/stores/Root";
import {
  SEARCH_MODE_PRODUCT,
  SEARCH_MODE_PRODUCT_CANDIDATE,
  SEARCH_MODE_REPORT,
  SEARCH_MODE_CONTACT,
  SEARCH_MODE_PROD_ORDER,
  SEARCH_MODE_SHIP_ORDER,
} from "@/composables/constants";

const listItems = [
  { caption: "Отчеты", code: SEARCH_MODE_REPORT },
  { caption: "Товары", code: SEARCH_MODE_PRODUCT },
  { caption: "Образцы", code: SEARCH_MODE_PRODUCT_CANDIDATE },
  { caption: "Контакты", code: SEARCH_MODE_CONTACT },
  { caption: "Производство", code: SEARCH_MODE_PROD_ORDER },
  { caption: "Контейнеры", code: SEARCH_MODE_SHIP_ORDER },
] as Array<ListItem>;

export default defineComponent({
  components: { BaseBtnDropdown, BaseDropdownList },
  data() {
    return {
      listItems,
      selectedItem: null as null | ListItem,
    };
  },
  created() {
    const selectedItem = listItems.filter((item) => {
      return item.code == this.searchModelName;
    });
    this.selectedItem = selectedItem ? selectedItem[0] : listItems[0];
  },
  computed: {
    /**
     * Возвращает надпись для заголовка с выбранным элементом
     */
    caption(): string {
      return this.selectedItem ? this.selectedItem.caption : "";
    },
    /**
     * Возвращает массив со всеми возможными значениями, кроме выбранного
     * @return array
     */
    items(): Array<ListItem> {
      return this.listItems.filter((item) => {
        return item.code != this.selectedItem?.code;
      });
    },
    /**
     * Возвращает название модели для поиска
     */
    searchModelName() {
      return useRootStore().searchModelName;
    },
  },
  methods: {
    /**
     * Обработчик выбора элемента из списка
     * @param listItem выбранный элемент
     */
    handleSelect(listItem: ListItem) {
      const $dropdown = this.$refs.dropdown as InstanceType<
        typeof BaseBtnDropdown
      >;
      $dropdown.close();
      this.selectedItem = listItem;
      this.$emit("setSearchModelName", listItem.code);
    },
  },
});
