import { setDashboardKey } from "@/composables/local_storage";
import User from "@/models/base/User";
import UserState from "@/models/stores/UserState";
import { defineStore } from "pinia";

export const USER_ID_CHRISTOFOROV = 14;

export const useUserStore = defineStore("User", {
  state: () =>
    ({
      hasAuth: false,
      id: null,
      name: null,
      avatar: null,
      employee_id: null,
    } as UserState),
  actions: {
    setUser(user: null | User) {
      if (user) {
        this.hasAuth = true;
        this.id = user.id;
        this.name = user.name;
        this.avatar = user.avatar;
        this.employee_id = user.employee_id;
      } else {
        this.hasAuth = false;
        this.id = null;
        this.name = null;
        this.avatar = null;
        this.employee_id = null;
      }
      const userId = user ? user.id : null;
      setDashboardKey(userId);
      localStorage.setItem("cuid", userId ? userId + "" : "");
      localStorage.setItem(
        "ceid",
        user?.employee_id ? user.employee_id + "" : ""
      );
    },
    /**
     * Возвращает флаг является ли пользователь авторизованным
     * @returns boolean
     */
    isAuthorizedUser(): boolean {
      return this.hasAuth;
    },
  },
});
