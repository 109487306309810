
import AutocompleteResponse from "@/models/base/AutocompleteResponse";
import { useRootStore } from "@/stores/Root";
import { PropType, StyleValue, defineComponent } from "vue";

export default defineComponent({
  props: {
    cursorIndex: {
      type: Number,
      required: true,
    },
    autocompleteData: {
      type: Array as PropType<AutocompleteResponse[]>,
      required: true,
    },
    autocompleteWidth: {
      type: Number,
      required: false,
    },
  },
  computed: {
    /**
     * Возвращает css переменную с base z-index
     * @returns object
     */
    autocompleteCssVar(): StyleValue {
      const target = document.querySelector(
          ".navigation .input"
        ) as HTMLElement,
        targetRect = target.getBoundingClientRect();
      return {
        "--base-z-index": useRootStore().getZIndex(),
        left: targetRect.left + "px",
        "--autocomplete-dropdown-width": this.autocompleteWidth
          ? this.autocompleteWidth + "px"
          : "auto",
      };
    },
  },
  methods: {
    /**
     * Возвращает классы для элемента списка
     * @param number index
     * @return string[]
     */
    autocompleteItemClasses(index: number): string[] {
      const result = ["autocomplete-item"];
      if (index == this.cursorIndex) {
        result.push("autocomplete-item--cursor");
      }

      return result;
    },
    /**
     * Возвращает строку с выделенной частью тегом span, которая совпадает с поиском
     * @param {string} value
     * @returns string
     */
    getPreparedValue(value: string) {
      const result = value.replace("<b>", "<span class='text-primary'>");
      return result.replace("</b>", "</span>");
    },
    /**
     * Подставляет выбранное значение в строку с поиском
     * @param {AutocompleteResponse} item
     */
    clickBtn(item: AutocompleteResponse): void {
      this.$emit("setAutocompleteSearchFilterValue", item);
    },

    /**
     * Возвращает элемент с автодополнением под курсором
     * @returns HTMLDivElement | null
     */
    getCursorItem(): HTMLDivElement | null {
      const listContainer = this.$refs.list as HTMLElement,
        index = this.cursorIndex;

      return listContainer.querySelector(
        ".autocomplete-item:nth-child(" + index + ")"
      );
    },
    /**
     * Инициирует событие выбора элемента под курсором
     */
    selectItemUnderCursor() {
      const activeElement = this.getCursorItem();
      if (activeElement) {
        activeElement.dispatchEvent(new Event("click"));
      }
    },
    /**
     * Определяет, что полученный элемент autocomple является тэгом
     * @param {AutocompleteResponse}
     * @returns {boolean}
     */
    isTagItem(autocompleteItem: AutocompleteResponse): boolean {
      return !!autocompleteItem.value.match("tag-");
    },
    /**
     * Возвращает класс для элемента autocomplete, если он относится к тэгам
     * @param {AutocompleteResponse} autocompleteItem
     * @returns {string}
     */
    autocompleteTagItemClass(autocompleteItem: AutocompleteResponse): string {
      let result = "";
      if (this.isTagItem(autocompleteItem)) {
        result = "autocomplete-tag-item";
      }
      return result;
    },
  },
});
