import { callApi } from "@/composables/api-client";
import { useRootStore } from "@/stores/Root";
import User from "@/models/base/User";

/**
 * Делает запрос на аутентификацию пользователя
 * @param {string} email
 * @param {string} password
 * @returns {Promise<number | null>}
 */
export function login(email: string, password: string): Promise<number | null> {
  return new Promise((resolve, reject) => {
    useRootStore().showPreloader();
    callApi<number>("auth/login", {
      email,
      password,
    })
      .then((apiResponse) => {
        resolve(apiResponse);
      })
      .catch((e) => {
        reject(e);
      })
      .finally(() => {
        useRootStore().hidePreloader();
      });
  });
}

/**
 * Делает запрос на разлогинивание пользователя
 * @returns {Promise<void>}
 */
export function logout(): Promise<void> {
  return new Promise((resolve) => {
    useRootStore().showPreloader();
    callApi<number>("auth/logout")
      .then(() => {
        resolve();
      })
      .finally(() => {
        useRootStore().hidePreloader();
      });
  });
}
// Возвращает данные о текущем пользователе приложения
export async function getUser(): Promise<User | null> {
  try {
    return await callApi<User>("auth/get_user");
  } catch (error) {
    return null;
  }
}
