import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53ea75bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.code,
        class: _normalizeClass({
        'list-item': true,
        active: !!_ctx.selectedItem && item.code == _ctx.selectedItem.code,
      }),
        onClick: ($event: any) => (_ctx.selectItem(item))
      }, _toDisplayString(item.caption), 11, _hoisted_2))
    }), 128))
  ]))
}