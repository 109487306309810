import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppStatus = _resolveComponent("AppStatus")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.wrapperClass)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode("Загрузка...")
      ])
    ], 2), [
      [_vShow, !_ctx.isSearchOpen]
    ]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createBlock(_component_AppStatus, { key: 0 }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}