import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isRelativePath)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.url,
        target: _ctx.target
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "target"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.url,
        target: _ctx.target,
        onClick: _cache[0] || (_cache[0] = 
      (event) => {
        _ctx.openUrl(event);
      }
    )
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
}