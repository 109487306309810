/*
 * Обертка над axios
 */
import axios, { AxiosInstance } from "axios";
import { getBaseUrl, isDevEnv } from "@/composables/constants";
import ApiRequestHeaders from "@/models/base/ApiRequestHeaders";
import ApiResponse from "@/models/base/ApiResponse";

const getApiBaseUrl = () => {
    return getBaseUrl() + "/frontend-api";
  },
  getContentHeaders = (): ApiRequestHeaders => {
    const result = {
      //"Content-type": "application/json",
      // "Content-type": "multipart/form-data",
    } as ApiRequestHeaders;
    if (isDevEnv()) {
      result["Authorization-Debug"] = localStorage.getItem("dev_token");
    }
    return result;
  },
  apiClient: AxiosInstance = axios.create({
    baseURL: getApiBaseUrl(),
    headers: getContentHeaders(),
  }),
  // Готовит значение params для post запроса
  _preparePostParams = (postData?: object | string | undefined) => {
    let params: object;
    if (typeof postData == "object") {
      params = postData;
    } else if (typeof postData == "string") {
      params = {
        payload: postData,
      };
    } else {
      params = {};
    }
    return params;
  };
/**
 * Совершает вызов к API бэкенда
 * @param {string} method Например "controller/action" ("/" в начале не требуется)
 * @param {object | string | undefined} postData Параметры запроса
 */
function callApi<T>(
  method: string,
  postData?: object | string | undefined
): Promise<T> {
  return new Promise((resolve, reject) => {
    const params = _preparePostParams(postData),
      headers = getContentHeaders();

    headers["Content-Type"] =
      params instanceof FormData ? "multipart/form-data" : "application/json";
    return apiClient
      .post(method, params, {
        headers,
      })
      .then((response) => {
        try {
          if ("goal" in response.data && "data" in response.data) {
            const apiResponse = Object.assign({
              ...response.data,
            }) as ApiResponse<T>;
            // Ответ HTTP 200
            if (apiResponse.goal == "ok") {
              // Успех
              resolve(apiResponse.data);
            } else if (apiResponse.goal == "fail" && "msg" in apiResponse) {
              reject({
                msg: apiResponse.msg,
                data: apiResponse.data,
              });
            } else {
              reject({
                msg: "unknown error",
                data: apiResponse,
              });
            }
          } else {
            reject({
              msg: "unknown error",
              data: response,
            });
          }
        } catch (e) {
          reject({
            msg: "unknown error",
            data: response,
          });
        }
      })
      .catch((e) => {
        if (e.response.status == 401) {
          // Пользователь не прошел аутентификацию, нужно перенаправить его на страницу входа
          location.href = "/spa/auth/login";
        }
        reject({
          msg: "unhandled error",
          data: e.response,
        });
      });
  });
}

/**
 * Позволяет скачать файл пользователю
 * @param blob содержимое файла
 * @param fileName название файла
 */
function simulateDownload(blob: Blob, fileName: string) {
  const downloadLink = window.document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
/**
 * Совершает вызов к API бэкенда для скачивания файла
 * @param {string} method Например "controller/action" ("/" в начале не требуется)
 * @param {object | string | undefined} postData Параметры запроса
 * @returns {Promise<ApiResponseFile>}
 */
function downloadFile(
  method: string,
  postData?: object | string | undefined
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const params = _preparePostParams(postData);
    return apiClient
      .post(method, params, {
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"] || "",
          contentDisposition = response.headers["content-disposition"];
        if (contentType.match(/json/i)) {
          // Ошибка, т.к вернулся JSON
          resolve(false);
        } else if (
          contentDisposition &&
          contentDisposition.match(/attachment/)
        ) {
          // Файл
          let fileName = response.headers["orig-file-name"] || "";
          fileName = decodeURIComponent(fileName).replaceAll("+", " ");
          simulateDownload(
            new Blob([response.data], {
              type: contentType,
            }),
            fileName
          );
          resolve(true);
        } else {
          // Ошибка
          throw new Error("unformatted response");
        }
      })
      .catch((e) => {
        reject({
          msg: "axios unknown error",
          data: e,
        });
      });
  });
}

export { callApi, apiClient, downloadFile };
//export default apiClient;
