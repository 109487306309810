import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83302b84"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLink = _resolveComponent("BaseLink")!

  return (_openBlock(), _createBlock(_component_BaseLink, {
    url: _ctx.menuItem.link,
    class: "menu-item",
    target: _ctx.defineTarget(_ctx.menuItem.link)
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "image",
        src: _ctx.imageLink
      }, null, 8, _hoisted_1),
      _createElementVNode("div", {
        class: "caption",
        innerHTML: _ctx.menuItem.name
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }, 8, ["url", "target"]))
}