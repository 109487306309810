import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = {
  key: 0,
  class: "btn-dropdown__btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "dropdown_caption",
      class: _normalizeClass({
      ['btn-dropdown ' + (_ctx.$attrs.class ? _ctx.$attrs.class : '')]: true,
      'btn-dropdown--open': _ctx.isOpen,
      'btn-dropdown--shadow': _ctx.isOpen && _ctx.showDropdownCaptionShadow,
      'dropdown-color-active': _ctx.hasChangeColorCaption && _ctx.isOpen,
    }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args))),
      style: _normalizeStyle(_ctx.cssVars),
      title: _ctx.title
    }, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "caption", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.caption), 1)
        ])
      ]),
      (_ctx.showDropdownBtn)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass({ 'btn-dropdown__btn-icon': true, is_active: _ctx.isActive })
            }, null, 2)
          ]))
        : _createCommentVNode("", true)
    ], 14, _hoisted_1),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: "btn-dropdown__backdrop",
              style: _normalizeStyle(_ctx.backdropContentStyle),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, null, 4),
            _createElementVNode("div", {
              ref: "content",
              style: _normalizeStyle(_ctx.dropdownContentStyle),
              class: _normalizeClass({
          ['btn-dropdown__content ' + (_ctx.$attrs.class ? _ctx.$attrs.class : '')]: true,
          'btn-dropdown__content--open': _ctx.isOpen,
          'btn-dropdown__content--shadow': _ctx.isOpen && _ctx.showDropdownContentShadow,
        }),
              "on:closeDropdown": _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 38)
          ], 64))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}