import { callApi } from "@/composables/api-client";
import MenuItem from "@/models/index/index/MenuItem";
import { useRootStore } from "@/stores/Root";

/**
 * Возвращает список недавно просмотренных пользователем страниц
 * @returns {Promise<MenuItem[]>}
 */
export function loadMostViewedPagesList(): Promise<MenuItem[] | null> {
  return new Promise((resolve) => {
    useRootStore().showPreloader();
    callApi<MenuItem[]>("index/load_most_visited_pages")
      .then((apiResponse) => {
        resolve(apiResponse as MenuItem[]);
      })
      .catch(() => {
        resolve(null);
      })
      .finally(() => {
        useRootStore().hidePreloader();
      });
  });
}

/**
 * Помечает в статистике посещения отчетов, что пользователь посетил пункт меню
 * @param {string} url
 * @returns {Promise<string | null>}
 */
export function saveMenuItemVisit(url: string): Promise<string | null> {
  return new Promise((resolve) => {
    callApi<string | null>("index/save_menu_item_visit", {
      url: "/spa" + url,
    })
      .then((apiResponse) => {
        resolve(apiResponse);
      })
      .catch(() => {
        resolve(null);
      });
  });
}

/**
 * Проверяет есть ли у пользователя доступ к переданной странице
 * @param {string} url
 * @returns {Promise<string | null>}
 */
export async function checkRouteAccess(url: string) {
  return await callApi<boolean | null>("index/check_route_access", {
    url: "spa" + url,
  });
}
/**
 * Запрашивает данные по возможности доступа пользователя к элементам интерфейса
 * @param {string} key
 * @returns {Promise<ApiResponse<boolean>>}
 */
export function checkUiAccess(key: string): Promise<boolean> {
  return new Promise((resolve) => {
    callApi<boolean>("index/check_ui_access", { key })
      .then((apiResponse) => {
        resolve(apiResponse as boolean);
      })
      .catch(() => {
        resolve(false);
      });
  });
}
