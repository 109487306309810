import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseDropdownList = _resolveComponent("BaseDropdownList")!
  const _component_BaseBtnDropdown = _resolveComponent("BaseBtnDropdown")!

  return (_openBlock(), _createBlock(_component_BaseBtnDropdown, {
    ref: "dropdown",
    caption: _ctx.caption,
    class: "type-search-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseDropdownList, {
        items: _ctx.items,
        "model-value": _ctx.selectedItem,
        "show-dropdown-caption-shadow": false,
        "onUpdate:modelValue": _ctx.handleSelect
      }, null, 8, ["items", "model-value", "onUpdate:modelValue"])
    ]),
    _: 1
  }, 8, ["caption"]))
}