import { Popover } from "@/models/base/Popover";
import Modal from "@/models/base/Modal";
import { defineStore } from "pinia";
import RootState from "@/models/stores/RootState";
import { shallowRef } from "vue";
import NoHintDataPopover from "@/components/base/NoHintDataPopover.vue";
import { search } from "@/services/SiteService";
import { SearchModelNameValues } from "@/models/index/index/SearchModelNameValues";
import { PAGE_TITLE, SEARCH_MODE_REPORT } from "@/composables/constants";
import SearchResponse from "@/models/base/SearchResponse";

const Z_INDEX_MODAL_OPEN = 250,
  Z_INDEX_DEFAULT = 1;

export const useRootStore = defineStore("Root", {
  state: () =>
    ({
      isSearchOpen: false,
      isNarrowPage: false,
      popover: null,
      modal: null,
      pageTitle: PAGE_TITLE,
      preloadersCount: 0,
      resetViewCallback: null,
      mouse: {
        clientX: 0,
        clientY: 0,
        pageX: 0,
        pageY: 0,
        scrollX: 0,
        scrollY: 0,
      },
      appWidth: 0,
      searchModelName: SEARCH_MODE_REPORT,
      searchFilterValue: "",
      searchData: {
        page: 1,
        has_next_page: false,
        items: [],
      },
      showAutocomplete: false,
      searchIsLoading: false,
      navbarBlockHeight: null,
    } as RootState),
  actions: {
    // Сохраняем позицию курсора в свойство mouse
    storeMousePosition(event: MouseEvent) {
      this.mouse = {
        clientX: event.clientX,
        clientY: event.clientY,
        pageX: event.pageX,
        pageY: event.pageY,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      };
    },
    /**
     * Метод нужен для того, чтобы подгонять шапку отчета под его содержимое
     * @param isNarrowPageValue будут ли у страницы отступы с боков
     */
    setIsNarrowPage(isNarrowPageValue: boolean) {
      this.isNarrowPage = isNarrowPageValue;
    },
    /*
     * Переключение видимости результатов поиска/меню
     */
    toggleSearchOpen() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    /**
     * Устанавливает состояние поиска открыт или спрятан
     * @param {boolean} isSearchOpen
     */
    setIsSearchOpen(isSearchOpen: boolean) {
      this.isSearchOpen = isSearchOpen;
    },
    /**
     * Установить заголовок страницы
     * @param pageTitle новый заголовок страницы
     */
    setPageTitle(pageTitle: string) {
      this.pageTitle = pageTitle;
    },
    /**
     * Показывать preloader
     */
    showPreloader() {
      this.preloadersCount += 1;
    },
    /**
     * Помечает, что можно теперь навести курсор мыши на подсказку без того, чтобы она пропала
     */
    enableFrozenPopover() {
      const popover = this.popover;
      if (popover !== null) {
        // Отключение производится в showPopover
        popover.isFrozen = true;
      }
    },
    /**
     * Проставляет признак, что контент подсказки будет меняться по ширине
     */
    enableUseDynamicWidthContent() {
      const popover = this.popover;
      if (popover !== null) {
        popover.useDynamicWidthContent = true;
      }
    },
    /**
     * Скрыть preloader
     * @param {boolean} resetAll
     */
    hidePreloader(resetAll = false) {
      if (resetAll) {
        this.preloadersCount = 0;
      } else {
        this.preloadersCount -= 1;
      }
    },
    /**
     * Обновляет отображение подсказки popover
     * @param {null | Popover} popover если null, то popover'a нет или мы его хотим скрыть.
     */
    updatePopover(popover: null | Popover) {
      // if (popover === null) {
      //   console.log("catch me");
      //   return;
      // }
      this.popover = popover;
    },
    /**
     * Обновляет отображение всплывающего окна modal
     * @param {null | Modal} modal если null, то всплывающего окна нет или мы его хотим скрыть.
     */
    updateModal(modal: null | Modal) {
      this.modal = modal; //shallowRef<Modal>(modal as Modal);
    },
    /**
     * Возвращает z-index для текущего состояния.
     * Если открыто модальное окно, то оно будет больше, чем, когда это окно закрыто
     * @returns {number}
     */
    getZIndex(): number {
      return this.modal ? Z_INDEX_MODAL_OPEN : Z_INDEX_DEFAULT;
    },
    /**
     * Устанавливает или стирает обработчик событий, срабатывающий при уходе со страницы
     * @param callback обработчик или null для стирания обработчика
     */
    setResetViewCallback(callback: null | (() => void)) {
      this.resetViewCallback = callback;
    },
    /**
     * Запустить обработчик сброса страницы
     */
    resetView() {
      if (this.resetViewCallback) {
        this.resetViewCallback();
        this.resetViewCallback = null;
      }
    },
    /**
     * Преобразует текст в другую раскладку клавиатуры
     * @param {string} searchFilter
     * @return {string}
     */
    toAltKbd(searchFilter: string): string {
      const enLayout =
          "`qwertyuiop[]asdfghjkl;'zxcvbnm,.~QWERTYUIOP{}ASDFGHJKL:\"ZXCVBNM<>",
        ruLayout =
          "ёйцукенгшщзхъфывапролджэячсмитьбюЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮ",
        searchLayout = enLayout + ruLayout,
        replacementLayout = ruLayout + enLayout,
        items = searchFilter.split("");
      let result = "";
      for (let i = 0; i < items.length; i++) {
        const index = searchLayout.indexOf(items[i]);
        result += index == -1 ? items[i] : replacementLayout.charAt(index);
      }
      return result;
    },
    /**
     * Загружает данные для поиска
     * @returns Promise<boolean>
     */
    loadSearchData(): Promise<boolean> {
      this.searchIsLoading = true;
      return new Promise((resolve) => {
        search<SearchResponse>(
          this.searchModelName,
          this.searchFilterValue,
          this.searchData.page
        ).then((response) => {
          if (this.searchData.page == 1) {
            this.searchData.items = response.items;
          } else {
            this.searchData.items = this.searchData.items.concat(
              response.items
            );
          }
          this.searchData.has_next_page = response.has_next_page;
          this.searchData.page += 1;
          this.searchIsLoading = false;
          resolve(true);
        });
      });
    },
    /**
     * Записывает значение модели для поиска
     * @param {SearchModelNameValues} value
     */
    setSearchModelName(value: SearchModelNameValues) {
      this.searchModelName = value;
      this.searchData = {
        page: 1,
        has_next_page: false,
        items: [],
      };
    },
    /**
     * Записывает значение "Показать значения для автодополнения?"
     * @param {boolean} value
     */
    setShowAutocompleteValue(value: boolean) {
      this.showAutocomplete = value;
    },
    /**
     * Записывает номер страницы для запроса результатов поиска
     * @param {number} value
     */
    setSearchPage(value: number) {
      this.searchData.page = value;
    },
    /**
     * Записывает значение поиска
     * @param {string} value
     */
    setSearchFilterValue(value: string) {
      this.searchFilterValue = value;
    },
    /**
     * Записывает в свойство popover.component компонент с загрушкой пустых данных
     */
    setEmptyPopoverComponent() {
      const popover = this.popover;
      if (popover !== null) {
        popover.component = shallowRef(NoHintDataPopover);
      }
    },
    /**
     * Устанавливает максимальтную ширину страницы
     * Зависит от html элемента с тегом body
     */
    refreshAppWidth() {
      const bodyElement = document.querySelector("body");
      if (bodyElement) {
        // Тег body был успешно найден. Возвращаем его ширину
        this.appWidth = bodyElement.getBoundingClientRect().width;
      } else {
        // Failsafe на случай, если тег body не был найден
        this.appWidth = window.innerWidth;
      }
    },
  },
});
