
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  StyleValue,
} from "vue";
import { mapState } from "pinia";
import AppNavbar from "@/components/app/AppNavbar.vue"; // @ is an alias to /src
import AppPreloader from "@/components/app/AppPreloader.vue"; // @ is an alias to /src
import { useRootStore } from "@/stores/Root";
import { useUserStore } from "@/stores/User";
import { getBaseUrl, isDevEnv } from "@/composables/constants";
import User from "./models/base/User";
import { styles, NotifyStyles } from "./composables/notify_styles";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { kitThrottle } from "./composables/delays";
import { setDashboardKey } from "./composables/local_storage";
import { getUser } from "./services/AuthService";

// Декларация для нотификаций
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $notify: (message: string, style?: NotifyStyles) => void;
  }
}
// Обработчик движения курсора
const handleMousemove = kitThrottle((event: MouseEvent) => {
  useRootStore().storeMousePosition(event);
}, 100);
// Обработчик прокрутки
const handleScroll = kitThrottle((event: Event) => {
  // Скрываем подсказку, если делаем прокрутку окна
  const popover = useRootStore().popover;
  if (popover) {
    useRootStore().updatePopover(null);
  }
  event.stopPropagation();
}, 400);

export default defineComponent({
  components: {
    AppNavbar,
    AppPreloader,
    AppPopover: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "app.popover" */ "@/components/app/AppPopover.vue"
        )
    ),
    AppModal: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "app.modal" */ "@/components/app/AppModal.vue"
        )
    ),
    AppSearchResults: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "app.search-results" */ "@/components/app/AppSearchResults.vue"
        )
    ),
  },
  data() {
    return {
      isLoadedUser: false,
    };
  },
  beforeMount: function () {
    // Регистрируем глобально нотификации через $notify
    this.registerNotifyHelper();
    // Получаем пользователя либо редирект
    // Чтобы получение пользователя было синхронным при работе с localStorage, подкидываем его заранее
    const storedUserId = localStorage.getItem("cuid");
    if (storedUserId !== null) {
      // Если ранее пользователь был авторизован
      setDashboardKey(parseInt(storedUserId));
    }
    this.loadUser();
    // Добавляем обработчик события на прокрутку окна
    window.addEventListener("scroll", handleScroll);
    // Добавляем обработчик события отслеживания позиции курсора
    window.addEventListener("mousemove", handleMousemove);
  },
  unmounted() {
    // Убираем обработчик прокрутки
    //const body = document.querySelector("body") as HTMLElement;
    window.removeEventListener("scroll", handleScroll);
    // Убираем обработчик события отслеживания позиции курсора
    window.removeEventListener("mousemove", handleMousemove);
  },
  computed: {
    ...mapState(useRootStore, [
      "isSearchOpen",
      "preloadersCount",
      "popover",
      "modal",
    ]),
    isSearchOpen() {
      return useRootStore().isSearchOpen;
    },
    rootStore() {
      return useRootStore();
    },
    /**
     * Возвращает истину, если нужно показывать экран загрузки
     * @return {boolean}
     */
    isPreloaderVisible(): boolean {
      return this.preloadersCount > 0;
    },

    /**
     * CSS класс для обертки
     * @return string
     */
    wrapperClass(): string {
      let classes = ["wrapper"];
      if (this.isSearchOpen) {
        classes.push("search-open");
      }

      return classes.join(" ");
    },
    /**
     * Возвращает общие правила для контейнера с приложением
     * @return {StyleValue}
     */
    wrapperCssStyle(): StyleValue {
      return {
        "--base-z-index": useRootStore().getZIndex(),
      };
    },
  },
  methods: {
    /**
     * Регистрируем нотификации
     */
    registerNotifyHelper() {
      // Обертка для нотификаций
      const app = getCurrentInstance();
      // Создаем обертку для системных нотификаций. Функция вернет метод notify()
      const $q = useQuasar();
      if (app) {
        app.appContext.config.globalProperties.$notify = (
          message: string,
          style: NotifyStyles = "primary"
        ) => {
          $q.notify({
            message,
            html: true,
            position: "top-right",
            textColor: styles[style].textColor,
            color: styles[style].color,
            classes: "kitfort-notification",
            timeout: 5000,
          });
        };
      }
    },
    /**
     * Загружает данные об авторизации пользователя
     */
    loadUser() {
      getUser()
        .then((user) => {
          useUserStore().setUser(user as User | null);
          if (user == null) {
            // Для неавторизованных пользователей показываем страницу регистрации
            this.$router.push({ name: "authLogin" });
          }
        })
        .catch(() => {
          if (!isDevEnv) {
            location.href = getBaseUrl();
          }
        })
        .finally(() => {
          this.isLoadedUser = true;
        });
    },
    /**
     * Убирает ненужную подсказку, если есть и autocomplete
     */
    clearPopover(event: MouseEvent) {
      const popover = useRootStore().popover;
      if (popover != null) {
        const divElement = this.$refs.popover as InstanceType<
          typeof HTMLDivElement
        >;
        const parentElement = divElement[
          "$el" as keyof InstanceType<typeof HTMLDivElement>
        ] as Node;
        const childElement = event.target as Node;
        let contains = parentElement.contains(childElement);
        if (contains != true) {
          useRootStore().updatePopover(null);
        }
      }
    },
  },
});
