
import { defineAsyncComponent, defineComponent } from "vue";
import AppPageWrapper from "@/components/app/AppPageWrapper.vue";
import AppIconMenuItem from "@/components/app/AppIconMenuItem.vue";
import { useIndexStore } from "@/stores/index/Index";
import IndexAlarm from "@/components/index/index/IndexAlarm.vue";
import IndexAlarmData from "@/models/index/index/IndexAlarm";
import { logout } from "@/services/AuthService";
import { useUserStore } from "@/stores/User";

const indexAlarms = [
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "Продано за месяц 9 шт.",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#E6DBFF",
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "Пишем 50 дней",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#FFEE98",
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "12 плохих отзывов",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#FFCFEC",
  },
  {
    caption: "ATM-120-S / МОДУЛЬ [К отгрузке]",
    content:
      "Какие-то проблемы сывывфыв контейнером, можент быть все что угодно, лишь бы текст был достаточно длинным",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#9fbfc4",
    component: "IndexAlarmContainer",
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#FFB2A1",
    component: "IndexAlarmWithTable",
    table: [
      {
        caption: "Доступно",
        value: "3 945 шт",
      },
      {
        caption: "Продано за месяц",
        value: "8 шт",
      },
    ],
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "Опоздание на 12 дней",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#D7E7FF",
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "Опоздание на 50 дней",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#D7E7FF",
  },
  {
    caption: "КТ-2302 Подогреватель бутылочек",
    content: "Неоплата 12 дней",
    photo: "https://static.kitfort.ru/images/products/1713/image_256x256.jpg",
    color: "#B7FFB6",
  },
] as Array<IndexAlarmData>;

export default defineComponent({
  components: {
    AppPageWrapper,
    AppIconMenuItem,
    IndexAlarm,
    IndexAlarmWithTable: defineAsyncComponent(
      () => import("@/components/index/index/IndexAlarmWithTable.vue")
    ),
    IndexAlarmContainer: defineAsyncComponent(
      () => import("@/components/index/index/IndexAlarmContainer.vue")
    ),
  },
  created() {
    if (useUserStore().isAuthorizedUser()) {
      useIndexStore().loadMostViewedPagesList();
    }
  },

  data() {
    return {
      alarmItems: indexAlarms,
    };
  },
  computed: {
    /**
     * Возвращает список наиболле посещаемых страниц пользователем
     */
    mostVisitedPages() {
      return useIndexStore().mostVisitedPages;
    },
  },
  methods: {
    /**
     * Метод ля разлогинивания пользователя
     */
    logoutUser() {
      logout().then(() => {
        useUserStore().setUser(null);
        this.$router.push({ name: "authLogin" });
      });
    },
  },
});
