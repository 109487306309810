import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import Quasar from 'quasar/src/vue-plugin.js';;

import quasarUserOptions from "./quasar-user-options";
const app = createApp(App);

app.use(Quasar, quasarUserOptions).use(createPinia()).use(router);

// Обертка для нотификаций
app.mount("#app");
