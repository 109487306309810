
import { defineComponent } from "vue";
import { useRootStore } from "@/stores/Root";
export default defineComponent({
  computed: {
    /**
     * Возвращает истину, если отчет должен отображаться узким
     * @returns {boolean}
     */
    isNarrowPage(): boolean {
      return useRootStore().isNarrowPage;
    },
    /**
     * Возвращает класс для status
     * @returns {string}
     */
    statusClass(): string {
      const classes = ["status"];
      if (this.isNarrowPage) {
        classes.push("status--narrow");
      }
      return classes.join(" ");
    },
    /**
     * Открыта шторка с поиском?
     * @returns boolean
     */
    isSearchOpen(): boolean {
      return useRootStore().isSearchOpen;
    },
  },
});
