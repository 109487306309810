import { defineStore } from "pinia";
import { loadMostViewedPagesList } from "@/services/IndexService";
import IndexData from "@/models/index/index/IndexData";
import MenuItem from "@/models/index/index/MenuItem";
export const useIndexStore = defineStore("Index", {
  state: () =>
    ({
      mostVisitedPages: [] as MenuItem[],
    } as IndexData),
  actions: {
    /**
     * Загружает список недавно просмотренных страниц
     */
    async loadMostViewedPagesList() {
      const apiResponse = (await loadMostViewedPagesList()) as MenuItem[];
      this.mostVisitedPages = apiResponse;
    },
  },
});
