
import { defineComponent } from "vue";
import { useRootStore } from "@/stores/Root";
import { mapState } from "pinia";
import { PAGE_TITLE } from "@/composables/constants";
import { useUserStore } from "@/stores/User";

export default defineComponent({
  computed: {
    ...mapState(useRootStore, ["isSearchOpen", "pageTitle"]),
    /**
     * Возвращает истину, если это главная страница
     * @return {boolean}
     */
    isHomePage(): boolean {
      return this.$route.path === "/";
    },
    /**
     * Возвращает заголовок страницы по умолчанию
     * @return string
     */
    defaultPageTitle() {
      return PAGE_TITLE;
    },
    /**
     * Возвращает флаг является ли пользователь авторизованным
     * @return boolean
     */
    isAuthorizedUser() {
      return useUserStore().isAuthorizedUser();
    },
  },
  methods: {
    // При щелчке по надписи отчета из открытой шторки попадаем на главную
    handleClick() {
      if (this.isSearchOpen) {
        this.$router.push("/");
        useRootStore().setIsSearchOpen(false);
      }
    },
  },
});
