
import { useRootStore } from "@/stores/Root";
import { defineComponent, StyleValue } from "vue";

export default defineComponent({
  name: "BaseBtnDropdown",
  mounted() {
    const dropdownCaption = this.$refs.dropdown_caption as HTMLElement;
    this.setDropdownWidth(dropdownCaption);
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        this.refreshPosition(dropdownCaption);
      }
    });
    this.resizeObserver.observe(document.querySelector("body") as Element);
  },
  unmounted() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  props: {
    caption: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    hasChangeColorCaption: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDropdownBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultContentWidth: {
      type: String,
      required: false,
      default: "",
    },
    paddingTop: {
      type: Number,
      required: false,
      default: 0,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    // По умолчанию ширина выпадающего списка равна ширине элемента над ним
    lockWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDropdownCaptionShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDropdownContentShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      contentLeft: "0px",
      contentTop: 0,
      contentWidth: "0px",
      contentHeight: "auto",
      resizeObserver: null as null | ResizeObserver,
      dropdownWidth: 0,
    };
  },
  computed: {
    /**
     * Возвращает css переменные
     * @return object
     */
    cssVars(): StyleValue {
      return {
        "--dropdown-width": this.dropdownWidth
          ? this.dropdownWidth + "px"
          : "100%",
      };
    },
    backdropContentStyle(): StyleValue {
      const backdropPaddingBottom = 400;
      return {
        "--base-z-index": useRootStore().getZIndex(),
        "--backdrop-height": this.contentTop + backdropPaddingBottom + "px",
      };
    },
    dropdownContentStyle(): StyleValue {
      const width = this.defaultContentWidth
        ? this.defaultContentWidth
        : this.contentWidth;
      let result = {
        "--base-z-index": useRootStore().getZIndex(),
        top: this.contentTop - (this.paddingTop ? this.paddingTop : 0) + "px",
        left: this.contentLeft,
        [this.lockWidth ? "width" : "min-width"]: width,
        "max-width": this.lockWidth ? width : "100%",
      } as StyleValue;

      return result;
    },
  },
  methods: {
    /**
     * Определяет позицию для выпадающего списка
     * @param {HTMLElement} target Элемент под которым будет нарисован выпадающий список
     */
    refreshPosition(target: HTMLElement) {
      if (!target.parentElement) {
        return;
      }
      // const targetRect = target.parentElement.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();

      this.contentLeft = targetRect.left + window.scrollX + "px";
      this.contentTop = targetRect.top + targetRect.height + window.scrollY;
      this.contentWidth = targetRect.width + "px";
    },
    /**
     * Записывает ширину для блока с выпадающим списком
     * @param {HTMLElement} target Элемент под которым будет нарисован выпадающий список
     */
    setDropdownWidth(target: HTMLElement) {
      if (!target.parentElement) {
        return;
      }
      const targetRect = target.parentElement.getBoundingClientRect();
      this.dropdownWidth = targetRect.width;
    },
    /**
     * Открыть/закрыть список
     * @param {Event} event
     */
    toggleOpen(event: Event) {
      if (!this.disabled) {
        this.refreshPosition(event.currentTarget as HTMLElement);
        this.isOpen = !this.isOpen;
      }
      if (this.isOpen) {
        this.$emit("onOpen");
      }
    },
    /**
     * Закрывает выпадающий список
     */
    close() {
      this.isOpen = false;
    },

    /**
     * Открывает выпадающий список
     */
    open() {
      this.isOpen = true;
    },
  },
});
