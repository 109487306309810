
import { StyleValue, defineComponent } from "vue";
import AppLogo from "@/components/app/AppLogo.vue"; // @ is an alias to /src
import AppSearch from "@/components/app/AppSearch.vue";
import AppProfile from "@/components/app/AppProfile.vue";
import AppKitfortSite from "@/components/app/AppKitfortSite.vue";
import { useRootStore } from "@/stores/Root";
import { useUserStore } from "@/stores/User";

export default defineComponent({
  components: {
    AppLogo,
    AppSearch,
    AppProfile,
    AppKitfortSite,
  },
  data() {
    return {
      resizeObserver: null as null | ResizeObserver,
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const navbarContainer = this.$refs.navigation as HTMLElement;
        useRootStore().navbarBlockHeight =
          navbarContainer.getBoundingClientRect().height;
      }
    });
    this.resizeObserver.observe(this.$refs.navigation);
  },
  unmounted() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  computed: {
    /**
     * Возвращает флаг является ли пользователь авторизованным
     * @return boolean
     */
    isAuthorizedUser() {
      return useUserStore().isAuthorizedUser();
    },
    /**
     * Возвращает значение position sticky|inherit
     *   в зависимости от того открыт ли поиск и показывается ли список с автодополнением
     * @returns string
     */
    navigationPositionStyle(): StyleValue {
      return {
        position:
          useRootStore().isSearchOpen && useRootStore().showAutocomplete
            ? "inherit"
            : "sticky",
      } as StyleValue;
    },
    /**
     * Возвращает истину, если отчет должен отображаться узким
     * @returns {boolean}
     */
    isNarrowPage(): boolean {
      // return useRootStore().isNarrowPage;
      return false;
    },
    /**
     * Возвращает класс для navbar
     * @returns {string}
     */
    navigationClass(): string {
      const classes = ["navigation"];
      if (this.isNarrowPage) {
        classes.push("navigation--narrow");
      }
      return classes.join(" ");
    },
  },
});
