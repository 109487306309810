/**
 * Разные функции-обертки, препятствующие частым повторным вызовам других функций
 *
 * В том числе здесь же обертки над quasar библиотекой. В случае отказа от Quasar переопределить здесь
 */
import quasarThrottle from 'quasar/src/utils/throttle.js';import quasarDebounce from 'quasar/src/utils/debounce.js';;

const kitThrottle = quasarThrottle;
const kitDebounce = quasarDebounce;

export { kitThrottle, kitDebounce };
